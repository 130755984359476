import preact, { h, Component } from 'preact'
import { connect } from 'preact-redux'
import Sidebar from '../../components/Sidebar'
import Main from '../../components/Main'
import Footer from '../../components/Footer'
import { setForumId } from '../../../actions'
import { cdnUrl } from '../../../config'
import './styles.scss'

class Home extends Component {
  componentWillMount() {
    this.props.setForumId(this.props.matches.forumId)
  }

  render() {
    const { forumName, productId, stories, matches } = this.props

    const forumId = matches.forumId
    const isMainActive = matches.storyId && matches.storyId.length > 0
    const selectedStory = stories[matches.storyId]

    return (
      <div className="home">
        <div
          className={!isMainActive ? 'home--sidebar active' : 'home--sidebar'}
        >
          <Sidebar
            forumId={forumId}
            forumName={forumName}
            stories={Object.values(stories)}
          />
        </div>
        <div className={isMainActive ? 'home--main active' : 'home--main'}>
          {isMainActive && selectedStory ? (
            <Main
              forumId={forumId}
              forumName={forumName}
              productId={productId}
              story={selectedStory}
            />
          ) : (
            [
              <div className="home--index">
                <img
                  src={`${cdnUrl}/${forumId}.png`}
                  alt=""
                  className="home--logo"
                />
                <p className="text--emphasis">
                  Find product stories in your community
                </p>
              </div>,
              <Footer />,
            ]
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  forumName: state.forum.forumName,
  stories: state.forum.stories,
})

const actions = {
  setForumId,
}

export default connect(
  mapStateToProps,
  actions,
)(Home)
