import preact, { h } from 'preact'
import { recordClick } from '../../lib/analytics'

const ExternalLink = ({
  children,
  className,
  eventTargetType,
  eventTargetValue,
  ...props
}) => {
  const fullClassName = className ? `${className} link` : 'link'
  return (
    <a
      {...props}
      target="_blank"
      className={fullClassName}
      native
      onClick={() => recordClick(eventTargetType, eventTargetValue)}
    >
      {children}
    </a>
  )
}

export default ExternalLink
