import preact, { h } from 'preact'
import MetricLink from '../MetricLink'
import ExternalLink from '../ExternalLink'
import WidowlessText from '../WidowlessText'
import './styles.scss'

const Carousel = ({
  slides,
  selectedSlide,
  selectors: { getExitHref, getHref, getId, getImageSource, getTitle },
  title,
}) => {
  const selectedSlideId = getId(selectedSlide)
  return (
    <div className="carousel">
      {title && <p className="carousel--title">{title}</p>}

      {slides.length > 1 && (
        <ul className="carousel--list">
          {slides.map(slide => (
            <li className="carousel--slide">
              <MetricLink
                href={getHref(slide)}
                className={
                  getId(slide) === selectedSlideId
                    ? 'carousel--slide-link selected'
                    : 'carousel--slide-link'
                }
                eventTargetType="product"
                eventTargetValue={getId(slide)}
              >
                <img
                  src={getImageSource(slide)}
                  alt=""
                  className="carousel--slide-img"
                />
              </MetricLink>
            </li>
          ))}
        </ul>
      )}

      {selectedSlide && (
        <div className="carousel--current-slide">
          <img
            src={getImageSource(selectedSlide)}
            alt=""
            className="carousel--current-slide--img"
          />

          <div className="carousel--slide-title">
            <h3 className="text--subheading">
              <WidowlessText text={getTitle(selectedSlide)} />
            </h3>
            <ExternalLink
              href={getExitHref(selectedSlide)}
              className="btn carousel--slide-btn"
              eventTargetType="affiliate"
              eventTargetValue={selectedSlideId}
            >
              View product
            </ExternalLink>
          </div>
        </div>
      )}
    </div>
  )
}

export default Carousel
