import {
  ActionTypes,
  setForumName,
  setForumStories,
  setForumStoriesFetchError,
} from './actions'
import { getForumStories } from './lib/api'
import { getForumNameFromId } from './config'

const middleware = store => next => action => {
  const value = next(action)

  switch (action.type) {
    case ActionTypes.SET_FORUM_ID: {
      const state = store.getState()
      const forumName = getForumNameFromId(action.forumId)

      if (
        typeof forumName === 'string' &&
        state.forum.forumName !== forumName
      ) {
        store.dispatch(setForumName(forumName))
      }

      if (action.forumId !== state.forum.forumId || !!state.forum.stories) {
        store.dispatch(setForumStories({}))
        if (state.forum.storiesFetchErrored) {
          store.dispatch(setForumStoriesFetchError(false))
        }

        getForumStories(action.forumId)
          .then(data => {
            if (data.forumName) {
              store.dispatch(setForumName(data.forumName))
            }
            if (data.stories) {
              store.dispatch(setForumStories(data.stories))
            }
          })
          .catch(err => {
            store.dispatch(setForumStoriesFetchError(true))
            console.log(err)
          })
      }
      break
    }
  }

  return value
}

export default middleware
