import { ActionTypes } from '../actions'

const initialState = {
  forumName: '',
  forumId: '',
  stories: {},
  storiesFetchErrored: false,
}

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ActionTypes.SET_FORUM_NAME:
      return {
        ...state,
        forumName: action.forumName,
      }
    case ActionTypes.SET_FORUM_ID:
      return {
        ...state,
        forumId: action.forumId,
      }
    case ActionTypes.SET_FORUM_STORIES:
      return {
        ...state,
        stories: action.stories,
      }
    case ActionTypes.SET_FORUM_STORIES_FETCH_ERROR:
      return {
        ...state,
        storiesFetchErrored: action.isError,
      }
    default:
      return state
  }
}

export default reducer
