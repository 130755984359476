import preact, { h } from 'preact'
import MetricLink from '../../components/MetricLink'
import ExternalLink from '../../components/ExternalLink'
import Footer from '../../components/Footer'
import WidowlessText from '../../components/WidowlessText'
import { supportedForumIds, getForumNameFromId, cdnUrl } from '../../../config'
import './styles.scss'

const Index = () => (
  <main className="index">
    <header className="index--header">
      <img
        src={`${cdnUrl}/product-stories-logo.svg`}
        alt=""
        className="index--ps-logo"
      />
      <WidowlessText text="Find products people are talking about in online communities." />
    </header>

    <ul className="index--forum-list">
      {supportedForumIds.map(id => (
        <li className="index--list-item">
          <MetricLink
            href={`/${id}/`}
            className="index--link"
            eventTargetType="forum"
            eventTargetValue={id}
          >
            <img
              src={`${cdnUrl}/${id}.png`}
              alt=""
              className="index--forum-logo"
            />
            {getForumNameFromId(id)}
          </MetricLink>
        </li>
      ))}
    </ul>

    <div className="index--footer">
      <WidowlessText text="Stay up to date &ndash; follow Product Stories on social media." />
      <div className="index--social-icon--list">
        <ExternalLink
          eventTargetType="social_media"
          eventTargetValue="facebook"
          href="https://www.facebook.com/pg/productstories1/"
        >
          <img
            src={`${cdnUrl}/third-party/fb-logo.svg`}
            alt=""
            className="index--social-icon"
          />
        </ExternalLink>
        <ExternalLink
          eventTargetType="social_media"
          eventTargetValue="instagram"
          href="https://www.instagram.com/product.stories/"
        >
          <img
            src={`${cdnUrl}/third-party/ig-logo.png`}
            alt=""
            className="index--social-icon"
          />
        </ExternalLink>
      </div>
    </div>

    <Footer />
  </main>
)

export default Index
