const urlNameToAccountName = {
  controlbooth: 'controlbooth',
  'az-billiards': 'forumsazbilliards',
  'home-theater-forum': 'htf',
  'printer-knowledge': 'PrinterKnowledge',
  talkbass: 'talkbass',
  'tortoise-forum': 'tortoiseforum',
  'windsor-peak-press': 'windsorpeakpress',
}

export const getAccountUrl = accountName =>
  `${config.apiBase}/products/accounts/${
    urlNameToAccountName[accountName]
  }/stories`

const urlName = window.location.pathname.split('/')[1]

export const config = {
  accountName: urlNameToAccountName[urlName],
  apiBase: 'https://threadloom-backend.firebaseapp.com/v1',
  // apiBase:
  //   'http://localhost:5000/threadloom-backend/us-central1/products_app_v1/v1',
  apiKey: '9tCt6uQDUdAhDXrteHZxa3Dt',
  maxPostLengthChars: 200,
  maxLinkLengthChars: 30,
}

const forumIdToForumName = {
  controlbooth: 'ControlBooth',
  'az-billiards': 'AZBilliards',
  'home-theater-forum': 'Home Theater Forum',
  'printer-knowledge': 'Printer Knowledge',
  talkbass: 'TalkBass',
  'tortoise-forum': 'TortoiseForum',
  'windsor-peak-press': 'Windsor Peak Press',
}

export const getForumNameFromId = id => forumIdToForumName[id]

export const supportedForumIds = [
  'az-billiards',
  'home-theater-forum',
  'talkbass',
  'windsor-peak-press',
]

export const cdnUrl = window.cdnUrl || ''

const forumIdToDescriptionPhrases = {
  'az-billiards': [
    'billiard players',
    getForumNameFromId('az-billiards'),
    'billiards',
  ],
  'home-theater-forum': [
    'home theater enthusiasts',
    getForumNameFromId('home-theater-forum'),
    'home theater',
  ],
  talkbass: ['bass players', getForumNameFromId('talkbass'), 'bass guitars'],
  'windsor-peak-press': [
    'parents',
    getForumNameFromId('windsor-peak-press'),
    'parenting',
  ],
}

export const getForumDescription = id => {
  const phrases = forumIdToDescriptionPhrases[id]
  if (phrases) {
    return `Products ${phrases[0]} are talking about at ${
      phrases[1]
    }, an online, trusted community devoted to ${phrases[2]}.`
  }
  return 'Products people are talking about in online communities.'
}
