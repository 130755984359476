import preact, { h } from 'preact'
import Router from 'preact-router'
import Index from '../pages/Index'
import Home from '../pages/Home'
import ErrorPage from '../pages/ErrorPage'

const App = () => (
  <Router>
    <Index path="/" />
    <Home path="/:forumId/:storyId?/:productId?" />
    <ErrorPage default>404 Page not found</ErrorPage>
  </Router>
)

export default App
