import preact, { h, Component } from 'preact'
import './styles.scss'

class ShareBox extends Component {
  inputRef = null

  captureInputRef = element => {
    this.inputRef = element
  }

  selectInputText = () => {
    this.inputRef.select()
    this.inputRef.setSelectionRange(0, this.inputRef.value.length)
  }

  componentDidMount() {
    this.selectInputText()
  }

  copyToClipboard = () => {
    if ('clipboard' in navigator) {
      return navigator.clipboard.writeText(this.props.value)
    }
    this.selectInputText()
    document.execCommand('copy', false, null)
    return Promise.resolve()
  }

  handleCopyClick = () =>
    this.copyToClipboard().then(() => {
      if (typeof this.props.dismiss === 'function') {
        this.props.dismiss()
      }
    })

  render() {
    return (
      <div className="share-box">
        <div>
          <input
            type="text"
            value={this.props.value}
            ref={this.captureInputRef}
            className="share-box--input"
            contenteditable
          />
        </div>
        <button className="share-box--btn link" onClick={this.handleCopyClick}>
          Copy
        </button>
      </div>
    )
  }
}

export default ShareBox
