import preact, { h } from 'preact'
import MetricLink from '../MetricLink'
import WidowlessText from '../WidowlessText'
import { cdnUrl, getForumDescription } from '../../../config'
import './styles.scss'

const Sidebar = ({ forumId, forumName, stories }) => {
  const currentPath = window.location.pathname

  return (
    <aside className="sidebar">
      <h2 className="sidebar--header text--heading">
        <MetricLink href="/" eventTargetType="index" eventTargetValue="/">
          <img
            src={`${cdnUrl}/product-stories-logo.svg`}
            alt=""
            className="sidebar--logo"
          />
        </MetricLink>
        <MetricLink
          href={`/${forumId}/`}
          eventTargetType="forum"
          eventTargetValue={forumId}
        >
          {forumName}
        </MetricLink>
      </h2>

      <div className="sidebar--main">
        <p className="sidebar--desc">
          <WidowlessText text={getForumDescription(forumId)} />
        </p>

        <ul>
          {stories.map(story => {
            const title = story.originalPost.title
            const productNamePreview =
              story.products[0].title || 'Related product'
            const productImageUrl =
              story.products[0].imageUrl || story.products[0].affiliateImageUrl
            const moreTextCount = story.products.length - 1
            const storyId = story.originalPost.id
            const productId = story.products[0].id
            const sidebarPathMatch = `/${forumId}/${storyId}`
            const storyLink = `/${forumId}/${storyId}/${productId}`

            return (
              <li>
                <MetricLink
                  href={storyLink}
                  className={
                    currentPath.startsWith(sidebarPathMatch)
                      ? 'sidebar--list-item selected'
                      : 'sidebar--list-item'
                  }
                  eventTargetType="story"
                  eventTargetValue={storyId}
                >
                  <p className="sidebar--list-item--title text--emphasis">
                    {title}
                  </p>
                  <p className="text-light sidebar--list-item--desc">
                    {productImageUrl && (
                      <span className="sidebar--list-item--img-wrapper">
                        <img
                          src={productImageUrl}
                          alt=""
                          className="sidebar--list-item--img"
                        />
                      </span>
                    )}
                    <span className="sidebar--list-item--product">
                      {productNamePreview}
                    </span>
                    {moreTextCount > 0 && (
                      <span>&nbsp;&amp; {moreTextCount} more</span>
                    )}
                  </p>
                </MetricLink>
              </li>
            )
          })}
        </ul>
      </div>
    </aside>
  )
}

export default Sidebar
