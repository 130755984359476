import preact, { h } from 'preact'
import Avatar from '../Avatar'
import RichText from '../RichText'
import './styles.scss'

const Quote = ({ className, post }) => (
  <div className={className ? `${className} quote` : 'quote'}>
    <div className="quote--body">
      <RichText text={post.snippet} readMoreUrl={post.url} />
    </div>

    <div className="quote--author text--small">
      <Avatar src={post.authorAvatarUrl} className="quote--author-avatar" />
      <div>
        <p className="text-strong">
          {post.authorUserName || 'Community Member'}
        </p>
        <p>{post.authorReputationLevel}</p>
      </div>
    </div>
  </div>
)

export default Quote
