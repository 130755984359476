export const ActionTypes = {
  SET_FORUM_ID: 'SET_FORUM_ID',
  SET_FORUM_NAME: 'SET_FORUM_NAME',
  SET_FORUM_STORIES: 'SET_FORUM_STORIES',
  SET_FORUM_STORIES_FETCH_ERROR: 'SET_FORUM_STORIES_FETCH_ERROR',
}

export const setForumId = forumId => ({
  forumId,
  type: ActionTypes.SET_FORUM_ID,
})

export const setForumName = forumName => ({
  forumName,
  type: ActionTypes.SET_FORUM_NAME,
})

export const setForumStories = stories => ({
  stories,
  type: ActionTypes.SET_FORUM_STORIES,
})

export const setForumStoriesFetchError = isError => ({
  isError,
  type: ActionTypes.SET_FORUM_STORIES_FETCH_ERROR,
})
