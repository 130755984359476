import preact, { h, render } from 'preact'
import { Provider } from 'preact-redux'
import store from './lib/store'
import App from './ui/components/App'
import './ui/reset.css'
import './ui/main.scss'

const root = document.getElementById('preact-inject')
if (root) {
  root.innerHTML = ''
  render(
    <Provider store={store}>
      <App />
    </Provider>,
    root,
  )
}
