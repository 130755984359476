import preact, { h, Component } from 'preact'
import ExternalLink from '../ExternalLink'
import MetricLink from '../MetricLink'
import Quote from '../Quote'
import Footer from '../Footer'
import ShareBox from '../ShareBox'
import RichText from '../RichText'
import Carousel from '../Carousel'
import WidowlessText from '../WidowlessText'
import { getUserLocale } from '../../../lib/api'
import { cdnUrl } from '../../../config'
import './styles.scss'

const resetScrollPosition = () => {
  const scrollParent = document.querySelector('.home--main')
  if (scrollParent) {
    scrollParent.scrollTop = 0
  }
}

const printDateTime = inputDate => {
  let date = inputDate
  if (!(date instanceof Date)) {
    try {
      date = new Date(inputDate)
    } catch (_) {
      return ''
    }
  }
  const locale = getUserLocale
  const timeString = date.toLocaleTimeString(locale).replace(/:\d{2} /, ' ')
  const dateString = date.toLocaleDateString(locale)
  return `${timeString} ${dateString}`
}

const makeProductUrl = forumId => storyId => productId =>
  `/${forumId}/${storyId}/${productId}`

class Main extends Component {
  state = {
    shareButtonExpanded: false,
  }

  componentDidMount() {
    resetScrollPosition()
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.story.originalPost.id !== nextProps.story.originalPost.id) {
      resetScrollPosition()
    }
  }

  toggleShareButton = () =>
    this.setState(state => ({
      shareButtonExpanded: !state.shareButtonExpanded,
    }))

  render() {
    const { forumId, forumName, productId, story } = this.props

    const nonBreakingSpaceUnicode = '\u00a0'
    const originalPostBody = story.originalPost.snippet || ''
    const opAuthor = story.originalPost.authorUserName || 'Community Member'
    const opAuthorReputation =
      story.originalPost.authorReputationLevel || nonBreakingSpaceUnicode
    const opLinkUrl = story.originalPost.url.startsWith('http')
      ? story.originalPost.url
      : `http://${story.originalPost.url}`
    const opLinkMatches = opLinkUrl.match(/^https?:\/\/(www\.)?([^/]+)/)
    const opLinkName =
      opLinkMatches && opLinkMatches.length > 2 ? opLinkMatches[2] : forumName
    const threadId = story.originalPost.threadId
    const selectedProduct = story.products.find(
      product => product.id === productId,
    )
    const opPostDate =
      printDateTime(story.originalPost.createdAt) || nonBreakingSpaceUnicode

    const makeUrl = makeProductUrl(forumId)(story.originalPost.id)

    return (
      <main className="main">
        {this.state.shareButtonExpanded && (
          <div className="main--share-box main-mobile">
            <ShareBox
              value={window.location.href}
              dismiss={this.toggleShareButton}
            />
          </div>
        )}

        <div className="main-mobile main--nav">
          <MetricLink
            href={`/${forumId}/`}
            className="main--nav-item link"
            eventTargetType="back"
            eventTargetValue={forumId}
          >
            Back
          </MetricLink>
          <button
            className="main--nav-item main--share-btn link"
            onClick={this.toggleShareButton}
          >
            <img
              src={`${cdnUrl}/share.svg`}
              alt=""
              className="main--share-icon"
            />{' '}
            Share
          </button>
        </div>

        <article>
          <header>
            <div className="main--op--header">
              <h1 className="text--heading main--op--header-title">
                <WidowlessText text={story.originalPost.title} />
              </h1>
              <button
                className="main--share-btn main-desktop link"
                onClick={this.toggleShareButton}
              >
                <img
                  src={`${cdnUrl}/share.svg`}
                  alt=""
                  className="main--share-icon"
                />{' '}
                Share
              </button>
              {this.state.shareButtonExpanded && (
                <div className="main--share-box main-desktop">
                  <ShareBox
                    value={window.location.href}
                    dismiss={this.toggleShareButton}
                  />
                </div>
              )}
            </div>
            {opPostDate && <p className="text-light">Posted {opPostDate}</p>}
          </header>

          <RichText
            text={originalPostBody}
            readMoreUrl={story.originalPost.url}
            className="main--op--body text--emphasis"
          />

          <footer className="main--op--footer">
            <p className="main--op--footer-item text-light">
              Posted by <span className="text-strong">{opAuthor}</span>
              <br />
              {opAuthorReputation}
            </p>
            <p className="main--op--footer-item">
              <span className="text-light">View original thread at</span>
              <br />
              <ExternalLink
                href={opLinkUrl}
                eventTargetType="thread"
                eventTargetValue={threadId}
              >
                {opLinkName}
              </ExternalLink>
            </p>
          </footer>
        </article>

        <article>
          <div className="main--product-carousel">
            <Carousel
              slides={story.products}
              selectedSlide={selectedProduct}
              selectors={{
                getExitHref: product => product.affiliateUrl || product.url,
                getHref: product => makeUrl(product.id),
                getId: product => product.id,
                getImageSource: product =>
                  product.imageUrl || product.affiliateImageUrl,
                getTitle: product => product.title,
              }}
              title="Products discussed in this thread"
            />
          </div>

          <div className="main--product-posts">
            {selectedProduct.posts.map(post => (
              <Quote post={post} className="main--product-posts--post" />
            ))}
          </div>
        </article>

        <Footer />
      </main>
    )
  }
}

export default Main
