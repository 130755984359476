import preact, { h, Component } from 'preact'
import ExternalLink from '../ExternalLink'
import { recordClick } from '../../../lib/analytics'
import { config } from '../../../config'
import './styles.scss'

const getLengthWithTruncatedLinks = text => {
  let truncatedLinksTotalLength = 0
  const baseLength = text
    .split('[[[')
    .map(snippet => {
      const linkEndMarkerIndex = snippet.indexOf(']]]')
      let endOfLinkIndex = 0
      if (linkEndMarkerIndex !== -1) {
        truncatedLinksTotalLength += Math.min(
          config.maxLinkLengthChars,
          linkEndMarkerIndex,
        )
        endOfLinkIndex = linkEndMarkerIndex + 3
      }
      return snippet.substr(endOfLinkIndex)
    })
    .join('').length
  return baseLength + truncatedLinksTotalLength
}

class RichText extends Component {
  state = {
    expanded: false,
  }

  toggleExpansion = targetValue => () =>
    this.setState(state => {
      if (!state.expanded) {
        recordClick('expand_post', targetValue)
      }
      return {
        expanded: !state.expanded,
      }
    })

  componentWillReceiveProps(nextProps) {
    if (this.props.text !== nextProps.text) {
      this.setState({ expanded: false })
    }
  }

  render() {
    const { text, readMoreUrl, ...props } = this.props
    let idealLength = getLengthWithTruncatedLinks(text)
    const truncatedLength = text.length - idealLength
    const maxLength = config.maxPostLengthChars + Math.min(truncatedLength, 100)

    if (idealLength > maxLength) {
      // truncate the text: prefer to split along a word boundary
      const maxLengthText = text.substr(0, maxLength)
      idealLength = Math.max(
        maxLengthText.lastIndexOf(' '),
        maxLengthText.lastIndexOf('\r\n'),
      )
    }

    let idealString = text.substr(0, idealLength)
    if (idealString.lastIndexOf('[[[') > idealString.lastIndexOf(']]]')) {
      // trim the severed link href
      idealLength = idealString.lastIndexOf('[[[')
      idealString = idealString.substr(0, idealLength)
    }

    const insertableText = this.state.expanded ? text : idealString
    const textIsTruncated = text.length !== idealString.length

    const paragraphs = insertableText
      .replace(/\s+$/, '')
      .split(/([\r\n]| {3})+/)
      .filter(p => p.replace(/^\s*$/, '').length > 0)

    const readMoreHref = readMoreUrl.startsWith('http')
      ? readMoreUrl
      : `http://${readMoreUrl}`

    return (
      <div {...props}>
        {paragraphs.length > 0 ? (
          paragraphs.map((paragraph, index) => {
            const insertInLastParagraph =
              textIsTruncated && index === paragraphs.length - 1
            return (
              <p className="rich-text">
                {paragraph.split('[[[').map(snippet => {
                  const endMarker = snippet.indexOf(']]]')
                  const link = snippet.substr(0, endMarker)
                  const remainder = snippet.substr(endMarker + 3)
                  const maxLength = config.maxLinkLengthChars
                  if (endMarker !== -1) {
                    return [
                      <ExternalLink href={link}>
                        {link.length > maxLength
                          ? `${link.substr(0, maxLength)}...`
                          : link}
                      </ExternalLink>,
                      remainder,
                    ]
                  }
                  return snippet
                })}
                {insertInLastParagraph && !this.state.expanded && '... '}
                {insertInLastParagraph && (
                  <button
                    className="rich-text--btn link"
                    onClick={this.toggleExpansion(readMoreUrl)}
                  >
                    {this.state.expanded ? 'Show less' : 'Read more'}
                  </button>
                )}
              </p>
            )
          })
        ) : (
          <p className="rich-text">
            <ExternalLink
              href={readMoreHref}
              className="rich-text--btn empty"
              eventTargetType="post"
              eventTargetValue={readMoreUrl}
            >
              Read more
            </ExternalLink>
          </p>
        )}
      </div>
    )
  }
}

export default RichText
