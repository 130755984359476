import preact, { h } from 'preact'
import { Link } from 'preact-router/match'
import { recordClick } from '../../lib/analytics'

export default function MetricLink(props) {
  const { eventTargetType, eventTargetValue, ...other } = props
  return (
    <Link
      {...other}
      onClick={() => recordClick(eventTargetType, eventTargetValue)}
    >
      {props.children}
    </Link>
  )
}
