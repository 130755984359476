import preact, { h } from 'preact'
import ExternalLink from '../ExternalLink'
import './styles.scss'

const year = new Date().getFullYear()

const Footer = () => (
  <div className="footer">
    <p>
      © Copyright{' '}
      <ExternalLink
        href="https://threadloom.com"
        eventTargetType="external"
        eventTargetValue="https://threadloom.com"
      >
        Threadloom
      </ExternalLink>{' '}
      {year}
    </p>
    <p>
      Learn more about our{' '}
      <ExternalLink
        href="https://www.threadloom.com/privacy"
        eventTargetType="external"
        eventTargetValue="https://threadloom.com/privacy"
      >
        privacy policy
      </ExternalLink>
      .
    </p>
  </div>
)

export default Footer
