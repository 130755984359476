import preact, { h } from 'preact'

const ErrorPage = ({ children }) => (
  <div>
    <h1>Oops, that's an error!</h1>
    <div>{children}</div>
  </div>
)

export default ErrorPage
