import preact, { h } from 'preact'
import { cdnUrl } from '../../../config'
import './styles.scss'

const getNextColor = (colors => {
  const len = colors.length
  let rotatingColorIndex = 0
  return () => colors[rotatingColorIndex++ % len]
})(['614c8a', '6bceff', '82cca4', 'f8d459', 'fa8c7d', '933535'])

const Avatar = ({ src, className }) => (
  <div
    className={className ? `${className} avatar` : 'avatar'}
    style={{ backgroundColor: `#${getNextColor()}` }}
  >
    <img src={src || `${cdnUrl}/avatar.svg`} alt="" className="avatar--img" />
  </div>
)

export default Avatar
