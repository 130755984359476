import { config } from '../config'

export function recordClick(clickTarget, clickTargetId) {
  window.ga(
    'threadloomTracker.send',
    'event',
    clickTarget,
    'click',
    clickTargetId,
  )
  return sendTlEvent('click', {
    click_target: clickTarget,
    click_target_val: clickTargetId,
  })
}

export function recordView() {
  const checkRecordView = () => {
    if (window.ga && typeof window.ga.getAll === 'function') {
      sendTlEvent('view', {})
    } else {
      setTimeout(checkRecordView, 300)
    }
  }
  checkRecordView()
}

function getTracker() {
  return window.ga.getByName('threadloomTracker')
}

function sendTlEvent(eventType, baseEvent) {
  const url = formEventApiUrl()
  if (url.indexOf('undefined') !== -1) {
    return
  }

  const tlTracker = getTracker()
  const event = {
    account_name: config.accountName,
    external_client_id: tlTracker.get('clientId'),
    user_agent: navigator.userAgent,
    referrer: tlTracker.get('referrer'),
    request_url: window.location.href,
  }
  const body = {
    event_type: eventType,
    event_data: Object.assign(baseEvent, event),
  }
  const customHeaders = new Headers({
    'Content-Type': 'application/json',
    'X-Threadloom-PKey': config.apiKey,
  })
  return fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: customHeaders,
    body: JSON.stringify(body),
  })
}

function formEventApiUrl() {
  const urlBase = config.apiBase
  const accountName = config.accountName
  return `${urlBase}/products/accounts/${accountName}/events`
}

recordView()
