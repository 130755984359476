import azbilliards from '../archive/azbilliards.json'
import htf from '../archive/htf.json'
import talkbass from '../archive/talkbass.json'
import wpp from '../archive/wpp.json'
// import { getAccountUrl } from '../config'

export function getForumStories(forumId) {
  /**
   * API is deprecated: use hardcoded response
   */
  // const path = getAccountUrl(forumId)
  // return fetch(path, {
  //   method: 'GET',
  // }).then(response => {
  //   if (response.status === 200) {
  //     return response.json()
  //   }
  //   throw new TypeError(
  //     `Response status expected 200, but got ${response.status}`,
  //   )
  // })
  /**
   * Hardcoded response
   */
  let content = null
  switch (forumId) {
    case 'az-billiards':
      content = azbilliards
      break
    case 'home-theater-forum':
      content = htf
      break
    case 'talkbass':
      content = talkbass
      break
    case 'windsor-peak-press':
      content = wpp
      break
    default:
      return Promise.reject(new Error('No content'))
  }
  return Promise.resolve(content)
}

export function getUserLocale() {
  if ('Intl' in window) {
    try {
      return Intl.DateTimeFormat().resolvedOptions().locale
    } catch (_) {}
  }
  return 'en-US'
}
