import preact, { h } from 'preact'

const nonBreakingSpaceUnicode = '\u00a0'

const WidowlessText = ({ text }) => (
  <span>
    {String.prototype.replace.call(
      text,
      /\s(\S*)$/,
      `${nonBreakingSpaceUnicode}$1`,
    )}
  </span>
)

export default WidowlessText
